import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const AhorraPage = () => (
	<Layout>
		<Seo title="Ahorra" />
		<h3>Ahorra</h3>
	</Layout>
);

export default AhorraPage;
